// Charge le fichier ressources/js/boostrap.js
require('./bootstrap');
require('../../vendor/mouse0270/bootstrap-notify/bootstrap-notify');

import Sortable from 'sortablejs';



let images = document.getElementsByTagName("img");
Array.prototype.forEach.call(images, function (element) {
    element.setAttribute("loading", "lazy");
});

let categories = document.getElementsByClassName("categorietitre");
Array.prototype.forEach.call(categories, function (element) {
    element.addEventListener("click", function (elt) {
        let categorieId = this.getAttribute('data-produit');
        let produits = document.getElementById(categorieId);
        let classes = produits.classList;
        classes.toggle("range");
    });
});
/*
let liensVersSiteVitrine = document.getElementsByClassName("lien-vers");
Array.prototype.forEach.call(liensVersSiteVitrine, function (element) {
    element.addEventListener("click", function (elt) {
        let destination = this.getAttribute('data-url');
        alert(destination);
    });
});
*/
let alertAvertissement = document.getElementsByClassName("alert-avertissement");
Array.prototype.forEach.call(alertAvertissement, function (element) {
    element.addEventListener("click", function (event) {
        event.preventDefault();
        let destination = this.getAttribute('data-message');

        $.notify({
            title: destination,
            message: ''
        }, {
            delay: 3000,
            type: 'primary'
        }); 

//        alert(destination);
    });
});

let liensVoirMotDePasse = document.getElementsByClassName("voirpassword");
Array.prototype.forEach.call(liensVoirMotDePasse, function (element) {
    element.addEventListener("click", function (elt) {
        let target = this.getAttribute('data-target');
        let cible = document.getElementById(target);
        let statut = cible.getAttribute('type');
        if (statut == "text") {
            cible.setAttribute('type', 'password');
        } else if (statut == "password") {
            cible.setAttribute('type', 'text');
        }
    });
});

document.addEventListener("DOMContentLoaded", function(e) {
    simpleListe();
});

function simpleListe() {
    let elSortable = 'simpleList';
    let el = document.getElementById(elSortable);
    if (el) {
        var theSortable = Sortable.create(el, {
            delay: 0
        });
        
        // var Sortable = require('sortable.js');
        /*let theSortable = Sortable(el,
            {
                fallbackTolerance: 3,
                animation: 150
            }
        );*/
        window.sortable = theSortable;
// let sortable1 = Sortable.create(el);

        // Créer l'écouteur pour la soumission du formulaire
        // Le form doit avec une classe (.sortable) identique à l'ID (#sortable) des éléments 'sortable'
        // Dans le form, il doit y avoir un input.hidden identifié et nommé (#sortable)IdOrder

        let formSortable = document.querySelectorAll('.' + elSortable);
        Array.prototype.forEach.call(formSortable, function (element) {

            element.addEventListener("submit", function (event) {
                event.preventDefault();
                let elLi = document.querySelectorAll('#' + elSortable + ' li');
                let ordreSortable = [];
                Array.prototype.forEach.call(elLi, function (element) {
                    ordreSortable.push(element.getAttribute('data-id'));
                });
                document.getElementById(elSortable + 'IdOrder').value = JSON.stringify(ordreSortable);

                this.submit();
            });
        });
    }
}


/*
 * Réseaux sociaux
 */
let destroyRS = document.getElementsByClassName("destroyRS");
Array.prototype.forEach.call(destroyRS, function (element) {
    element.addEventListener("click", function (elt) {
        elt.preventDefault;
        let rsId = this.getAttribute('data-id');
        document.getElementById(rsId).remove();
    });
});

let idRs = 100;
let addRs = document.getElementById("formAddRs");
if (addRs) {
    addRs.addEventListener("submit", function (event) {
        event.preventDefault();
        let simpleList = document.getElementById("simpleList");

        let type = document.getElementById("type").value;
        let url = document.getElementById("url").value;

        li = '<li class="list-group-item d-flex align-items-center" id="rs-' + idRs + '" data-id="' + idRs + '" draggable="true"><input class="btn btn-primary mr-2 destroyRS" type="button" data-id="rs-' + idRs + '" value="X"> <img class="mr-2" src="http://127.0.0.1:8000/img/reseauxsociaux/' + type + '.svg" alt="">' + url + '</li>';

        let input = document.createElement('input');
        input.className = 'btn btn-danger mr-2 destroyRS';
        input.setAttribute('value', 'X');
        input.setAttribute('type', 'button');
        input.setAttribute('data-id', 'rs-' + idRs);

        let inputHiddenType = document.createElement('input');
        inputHiddenType.setAttribute('type', 'hidden');
        inputHiddenType.setAttribute('name', 'type[]');
        inputHiddenType.setAttribute('value', type);

        let inputHiddenUrl = document.createElement('input');
        inputHiddenUrl.setAttribute('type', 'hidden');
        inputHiddenUrl.setAttribute('name', 'url[]');
        inputHiddenUrl.setAttribute('value', url);

        let img = document.createElement('img');
        img.className = 'mr-2';
        img.src = '/img/reseauxsociaux/' + type + '.svg';
        img.setAttribute('alt', '');

        let li = document.createElement('li');
        li.className = 'list-group-item d-flex align-items-center';
        li.setAttribute('id', idRs);
        li.setAttribute('data-id', idRs);
        li.setAttribute('draggable', 'true');

        let newContent = document.createTextNode(url);

        li.appendChild(input);
        li.appendChild(inputHiddenType);
        li.appendChild(inputHiddenUrl);
        li.appendChild(img);
        li.appendChild(newContent);
        simpleList.appendChild(li);

        idRs++;
    });
}



// Prévisualiser l'image
let typeDeFichier = document.querySelector('input.preview[type="file"]');
if (typeDeFichier) {
    typeDeFichier.addEventListener("change", function(e) {
        let that = e.currentTarget;
        let imgpreview = that.getAttribute('data-targetidpreview');
        let filename = that.getAttribute('data-targetfilename');
        if (imgpreview) {
            if (that.files && that.files[0]) {
                if (filename) {
                    document.getElementById(filename).innerHTML = that.files[0].name;
                }
                let reader = new FileReader()
                reader.onload = (e) => {
                    targetImg = document.getElementById(imgpreview);
                    targetImg.setAttribute("src", e.target.result);
                }
                reader.readAsDataURL(that.files[0])
            }
        }
    }, false);
}

/*
// Swipe carousel Bootstrap : nécessite jquerymobile
$(document).ready(function () {
    $("#myCarousel").swiperight(function () {
        $(this).carousel('prev');
    });
    $("#myCarousel").swipeleft(function () {
        $(this).carousel('next');
    });
});




// Swipe : vanillaJS
document.addEventListener('touchstart', handleTouchStart, false);
document.addEventListener('touchmove', handleTouchMove, false);

var xDown = null;
var yDown = null;

function getTouches(evt) {
  return evt.touches ||             // browser API
         evt.originalEvent.touches; // jQuery
}

function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
};

function handleTouchMove(evt) {
    if ( ! xDown || ! yDown ) {
        return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
if (xDiff > 0) {
    // left swipe
} else {
    // right swipe
}                       
    } else {
    if (yDiff > 0) {
        // up swipe
    } else {
        // down swipe
    }
}
// reset values
xDown = null;
yDown = null;
};

// Swipe tout prêt : https://github.com/john-doherty/swiped-events

// Mieux : https://codepen.io/glebkema/pen/daLzpL


// https://npmcdn.com/flickity@2/dist/flickity.pkgd.js
*/



/* popup class="regPop" */
// Prépare la div+img qui affichera la popup

let popupTarget = document.getElementById("regPopUp");
if (!popupTarget) {
    let div = document.createElement("div", { "class" : "regPopUp" });
    div.setAttribute("id", "regPopUp");
    div.setAttribute("class", "regPopUp fermer");

    let img = document.createElement("img", { "class" : "regPopUp" });
    img.setAttribute("class", "regPopUp");
    img.setAttribute("src", "");

    // L'img est enfant de la div
    div.appendChild(img);

    // La div est dernier enfant de body
    let bo = document.body;
    bo.append(div);
}

let popup = document.getElementsByClassName("regPop");
Array.prototype.forEach.call(popup, function (element) {
    element.addEventListener("click", function (elt) {
        // Désactive le click
        elt.preventDefault();

        let popupTargetImg = document.querySelector("#regPopUp img");
        let regImg = this.getAttribute("href");
        popupTargetImg.setAttribute("src", regImg);

        let popupTarget = document.getElementById("regPopUp");
        popupTarget.classList.remove("fermer");

        popupTarget.addEventListener("click", function (elt) {
            elt.preventDefault();
            this.classList.add("fermer");
        });
    });
});

/*
function copy() {
    var copyText = document.getElementById("urlRestaurateur");
    copyText.select();
    console.log(copyText.select());
    document.execCommand("copy");
}

let copierUrl = document.getElementById("copyUrl");
if (copierUrl) {
    copierUrl.addEventListener("click", copy);
}
*/
function textToClipboard(text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}
let urlEtablissement = document.getElementById('urlEtablissement');
if (urlEtablissement) {
    let copy = document.getElementById('copyUrlEtablissement').addEventListener('click', function(e) {
        textToClipboard(urlEtablissement.innerText);
        $.notify({
            title: 'URL copiée dans le presse papier',
            message: ''
        }, {
            delay: 3000,
            type: 'primary'
        }); 
    });

}
