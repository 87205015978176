//REG window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
//REG     window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    // Charge le JS du framework boostrap
    require('bootstrap');

    // Sortable.js : ordonner les produits et les catégories
//    var Sortable = require('sortable.js');


/*
    var myList = document.querySelector('.sortable-list');
    var SortableElt = Sortable(myList, {
        dataIdAttr: 'data-id',
        'setData': function ( dataTransfer, dragEl) {
            dataTransfer.setData('Text', dragEl.textContent); // `dataTransfer` object of HTML5 DragEvent
            console.info('setData');
            ss
        },
        'onEnd': function (evt) {
            console.info('onEnd');
            zz
        },
        'onUpdate': function (evt) {
            console.info('onUpdate');
            aa
        },
        'onSort': function (evt) {
            console.info('onSort');
            dd
        },
        'onChange': function (evt) {
            console.info('onChange');
            ee
        }
    });
    console.log(SortableElt);
*/

/*
    var options = {
        animation: 1000
    }
    events = [
        'onChoose',
        'onStart',
        'onEnd',
        'onAdd',
        'onUpdate',
        'onSort',
        'onRemove',
        'onChange',
        'onUnchoose',
    ].forEach(function (name) {
        options[name] = function (evt) {
            console.log({
                'event': name,
                'this': this
            });
        };
    });
    var SortableElt = Sortable(simpleList, options);
*/

/*
    SortableElt.onChange(function (e) {
        console.log('onChange');
    });
/* */
    //var myList2 = document.querySelector('#simpleList');
    //console.log("pokpok");
    //Sortable.create(myList2);
    //console.log(Sortable);

} catch (e) {
    console.error(e);
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

//REG window.axios = require('axios');

//REG window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
